<template>
  <div class="home">
    <div class="bg-gray-50">
      <main class="w-2/3 mx-auto py-10">

<div class="frame-inner">
<h2>Datenschutzerklärung</h2>
<p>Wir freuen uns über Ihren Besuch auf unserer Webseite jonku.net und Ihr Interesse an unserem Unternehmen und unseren Angeboten. Für externe Links zu fremden Inhalten übernehmen wir trotz sorgfältiger inhaltlicher Kontrolle keine Haftung, da wir die Übermittlung dieser Information nicht veranlasst, den Adressaten der übermittelten Information und die übermittelten Informationen selbst nicht ausgewählt oder verändert haben.</p>
<p>Der Schutz Ihrer personenbezogenen Daten bei der Erhebung, Verarbeitung und Nutzung anlässlich Ihres Besuchs auf unseren Internetseiten ist uns ein wichtiges Anliegen und erfolgt im Rahmen der gesetzlichen Vorschriften, über die Sie sich z.B. unter www.bfd.bund.de informieren können.</p>
<p>Im Folgenden erläutern wir Ihnen, welche Informationen wir während Ihres Besuchs auf unseren Webseiten erfassen und wie diese genutzt werden:</p>
<h3>1. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h3>
<p>a) Beim Besuch der Webseite</p>
<p>Bei jedem Zugriff eines Kunden (oder sonstigen Besuchers) auf unserer Webseite werden durch den auf Ihrem Endgerät (Computer, Laptop, Tablet, Smartphone, etc.) zum Einsatz kommenden Internet-Browser automatisch Informationen an den Server unserer Webseite gesendet. Diese Informationen werden temporär in einem sog. Logfile (Protokolldatei) gespeichert.</p>
<p>Folgende Daten werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
</p><ul>
<li>IP-Adresse des anfragenden Rechners, sowie Geräte-ID oder individuelle Geräte-Kennung und Gerätetyp,
</li><li>Name der abgerufenen Datei und übertragene Datenmenge, sowie Datum und Uhrzeit des Abrufs,
</li><li>Meldung über erfolgreichen Abruf,
</li><li>anfragende Domain,
</li><li>Beschreibung des Typs des verwendeten Internet-Browsers und ggf. des Betriebssystems Ihres Endgeräts sowie der Name Ihres Access-Providers,
</li><li>Ihre Browser-Verlaufsdaten sowie Ihre standardmäßigen Weblog-Informationen,
</li><li>Standortdaten, einschließlich Standortdaten von Ihrem Mobilgerät. Bitte beachten Sie, dass Sie bei den meisten Mobilgeräten die Verwendung von Standortservices im Einstellungsmenü des Mobilgeräts steuern oder deaktivieren können.</li></ul>
<p>Unser berechtigtes Interesse gem. Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO zur Erhebung der Daten beruht auf den folgenden Zwecken:
Gewährleistung eines reibungslosen Verbindungsaufbaus und einer komfortablen Nutzung der Webseite,
Auswertung der Systemsicherheit und -stabilität sowie
zu weiteren administrativen Zwecken.</p>
<p>In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.	</p>
<p>b) Bei Nutzung unseres Kontaktformulars</p>
<p>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Webseite bereitgestelltes Formular Kontakt aufzunehmen. Dabei sind mindestens die folgenden Angaben erforderlich: , damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.<br>
Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt gem. Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.<br>
Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.<br>
</p>
<p>c) Bei Abschluss eines Vertragsverhältnisses</p>
<p>Bei Abschluss eines Vertragsverhältnisses auf unserer Webseite bitten wir Sie um die Angaben folgender personenbezogener Daten:</p>
- Daten, die Sie persönlich identifizieren wie Name und E-Mail-Adresse
- Kontaktdaten, wie Anschrift Rechnungs- und Lieferanschrift/ und Telefonnummer
- Fotos
- akademischer Titel
- Daten, die Ihr Unternehmen identifizieren, wie Firma, Anschrift, Kommunikationsdaten (E-Mail-Adresse, Telefon-, Faxnummer), ggf. USt-ID oder Steuernummer)
- Informationen zu Ihrem Zahlungsmittel
<p>- weitere personenbezogene Daten, zu deren Erfassung und Verarbeitung wir gesetzlich verpflichtet oder berechtigt sind und die wir für Ihre Authentifizierung, Identifizierung oder zur Überprüfung der von uns erhobenen Daten benötigen.</p>
<p>Die genannten Daten werden zur Abwicklung des Vertragsverhältnisses verarbeitet. Die Verarbeitung der Daten erfolgt aufgrund von Art. 6 Abs. 1 lit. b DSGVO. Die Speicherfrist ist auf den Vertragszweck und, sofern vorhanden, gesetzliche und vertragliche Aufbewahrungspflichten beschränkt.</p>
<p>e) Bei Anmeldung für unseren Newsletter</p>
<p>Sofern Sie nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter zu übersenden.<br>
Die Abmeldung ist jederzeit möglich, zum Beispiel über einen Link am Ende eines Newsletters. Alternativ können Sie Ihren Abmeldewunsch gerne auch jederzeit per E-Mail (am besten mit dem Betreff: „Abmeldung Newsletter“) senden.
</p>
<h3>2. Weitergabe von personenbezogenen Daten</h3>
<p>Eine Übermittlung Ihrer Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.</p>
<p>Wir geben Ihre Daten nur an Dritte weiter, wenn:</p>
<ul>
<li>Sie eine ausdrückliche Einwilligung dazu erteilt haben nach (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
</li><li>dies für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist (Art. 6 Abs. 1 lit. b DSGVO),
</li><li>eine gesetzliche Verpflichtung zur Weitergabe besteht (Art.6 Abs.1 lit. c DSGVO),
</li><li>die Weitergabe zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben (Art. 6 Abs. 1 S. 1 lit. f DSGVO).</li></ul>
<p>In diesen Fällen beschränkt sich der Umfang der übermittelten Daten jedoch nur auf das erforderliche Minimum.</p>
<p>Unsere Datenschutzbestimmungen stehen im Einklang mit den geltenden datenschutzrechtlichen Bestimmungen und die Daten werden nur in der Bundesrepublik Deutschland / Europäischen Union verarbeitet. Eine Übermittlung in Drittländer findet nicht statt und ist nicht beabsichtigt.</p>
<h3>3. Betroffenenrechte</h3>
<p>Auf Anfrage werden wir Sie gern informieren, ob und welche personenbezogenen Daten zu Ihrer Person gespeichert sind (Art.&nbsp;15 DSGVO), insbesondere über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling.
</p><p>
Ihnen steht zudem das Recht zu, etwaig unrichtig erhobene personenbezogene Daten berichtigen oder unvollständig erhobene Daten vervollständigen zu lassen (Art.&nbsp;16 DSGVO).
</p><p>
Ferner haben Sie das Recht, von uns die Einschränkung der Verarbeitung Ihrer Daten zu verlangen, sofern die gesetzlichen Voraussetzungen hierfür vorliegen (Art.&nbsp;18 DSGVO).
</p><p>
Sie haben das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen (Art.&nbsp;20 DSGVO).
</p><p>
Darüber hinaus steht Ihnen das sogenannte „Recht auf Vergessenwerden“ zu, d.h. Sie können von uns die Löschung Ihrer personenbezogenen Daten verlangen, sofern hierfür die gesetzlichen Voraussetzungen vorliegen (Art.&nbsp;17 DSGVO).
</p><p>
Unabhängig davon werden Ihre personenbezogenen Daten automatisch von uns gelöscht, wenn der Zweck der Datenerhebung weggefallen oder die Datenverarbeitung unrechtmäßig erfolgt ist.
</p><p>
Gemäß Art.&nbsp;7 Abs.&nbsp;3 DSGVO haben Sie das Recht Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen.
</p><p>
Sie haben zudem das Recht, jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch zu erheben, sofern ein Widerspruchsrecht gesetzlich vorgesehen ist. Im Falle eines wirksamen Widerrufs werden Ihre personenbezogenen Daten ebenfalls automatisch durch uns gelöscht (Art.&nbsp;21 DSGVO).
</p><p>
Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an: kontakt@jonku.net.
</p><p>
Bei Verstößen gegen die datenschutzrechtlichen Vorschriften haben Sie gem. Art.&nbsp;77 DSGVO die Möglichkeit, Beschwerde bei einer Aufsichtsbehörde zu erheben.
</p>
<h3>4. Dauer der Datenspeicherung</h3>
<p>Die erhobenen Daten werden solange bei uns gespeichert, wie dies für die Durchführung der mit uns eingegangen Verträge erforderlich ist oder Sie Ihr Recht auf Löschung oder Ihr Recht auf Datenübertragung auf ein anderes Unternehmen nicht ausgeübt haben.</p>
<h3>5. Cookies</h3>
<p>Wir setzen auf unserer Webseite keine Cookies ein.
</p><h3>6. Online-Marketing/ Analyse-Tools</h3>
<p>keine</p>
<p>Links zu unseren Internetauftritten in sozialen Netzwerken</p>
<p>Auf unserer Webseite haben wir Links zu unseren Internetauftritten in den sozialen Netzwerken eingebunden. Wir weisen darauf hin, dass es sich hierbei nur um Verlinkungen handelt, die zu unserem Internetauftritt in den genannten Netzwerken weiterleiten, es handelt sich nicht um sog. Plugins, mit denen Sie bspw. Informationen auf unserer Webseite in den Netzwerken „teilen“ oder „liken“ könnten. Soweit uns bekannt ist, ist es technisch nicht möglich, dass soziale Netzwerke über die bloßen Verlinkungen personenbezogene Daten auf unserer Webseite erheben können. Zweck und Umfang der Datenerhebung nach der Weiterleitung entnehmen Sie bitte den Datenschutzinformationen des jeweiligen Netzwerkes.</p>
<h3>Datensicherheit</h3>
<p>Wir sind um alle notwendigen technischen und organisatorischen Sicherheitsmaßnahmen bemüht, um Ihre personenbezogenen Daten so zu speichern, dass sie weder Dritten noch der Öffentlichkeit zugänglich sind. Sollten Sie mit uns per E-Mail in Kontakt treten wollen, so weisen wir Sie darauf hin, dass bei diesem Kommunikationsweg die Vertraulichkeit der übermittelten Informationen nicht vollständig gewährleistet werden kann. Wir empfehlen Ihnen daher, uns vertrauliche Informationen ausschließlich über den Postweg zukommen zu lassen.</p>
<h3>Aktualität und Änderung dieser Datenschutzerklärung</h3>
<p>Diese Datenschutzerklärung ist aktuell gültig. </p>
<p>Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Webseite unter https://jonku.net/datenschutz von Ihnen abgerufen und ausgedruckt werden.</p>
<p>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen</p>
<p>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:</p>
<p>Joachim Rothkegel
Am Elisabethstein 8
26215 Wiefelstede
0441 249 261 0
info@jonku.net</p>
Datenschutzbeauftragter: Joachim Rothkegel
Am Elisabethstein 8
26215 Wiefelstede
0441 249 261 0
rothkegel@jonku.net
</div>

      </main>

      <footer class="bg-gray-100" aria-labelledby="footerHeading">
        <h2 id="footerHeading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
          <div class="xl:grid xl:grid-cols-1 xl:gap-8">
            <div class="grid grid-cols-3 gap-8 xl:col-span-2">
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Support
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                        Dokumentation
                      </a>
                    </li>

                    <li>
                      <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                        Hilfe
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Kontakt
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <a href="tel:00494412492610" class="text-base text-gray-500 hover:text-gray-900">
                        0441 249 261 0
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@jonku.net" class="text-base text-gray-500 hover:text-gray-900">
                        info@jonku.net
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Bürozeiten
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <p class="text-base text-gray-500">
                        Montag bis Freitag
                      </p>
                    </li>
                    <li>
                      <p class="text-base text-gray-500">
                        9 - 13 Uhr
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-6">
            <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; JONKU
            </p>
            <div class="md:order-2">
              <a href="impressum" class="text-base text-gray-500 hover:text-gray-900">
                Impressum
              </a>
              <a href="datenschutz" class="text-base text-gray-500 hover:text-gray-900 mx-6">
                Datenschutz
              </a>
              <a href="agb" class="text-base text-gray-500 hover:text-gray-900">
                AGB
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Privacy',

  components: {

  }
}
</script>