<template lang="">
  <div>
    <div class="grid grid-cols-6 gap-y-6 gap-x-4">
      <v-label class="ol-start-1 col-span-3" title="IBAN">
        <v-input 
          v-model="bank.iban" 
          name="given-name" 
          type="text" 
          :error="computedIbanErrorMessage" 
          @blur="validateIban" 
        />
      </v-label>

      <v-label class="col-span-3" title="Kontoinhaber">
        <v-input 
          v-model="bank.bankOwner" 
          name="given-name" 
          :error="validation.invoice_bank_owner ? validation.invoice_bank_owner.$errors[0] : null" 
          type="text" 
        />
      </v-label>
    </div>

    <div class="grid grid-cols-6 gap-y-6 gap-x-4 mt-4">
      <v-label class="col-start-1 col-span-3" title="Bankname">
        <v-input 
          v-model="bank.bankname"
          :error="validation.invoice_bank_name ? validation.invoice_bank_name.$errors[0] : null" 
          type="text"
          class="bg-gray-200"
          disabled  
        />
      </v-label>
      <v-label class="col-span-3" title="BIC">
        <v-input 
          v-model="bank.bic"
          :error="validation.invoice_bic ? validation.invoice_bic.$errors[0] : null"
          type="text"
          class="bg-gray-200"
          disabled  
        />
      </v-label>
    </div>
  </div>
</template>
<script>
import VInput from '../components/v-input.vue';
import VLabel from '../components/v-label.vue';
import { useVuelidate } from '@vuelidate/core'

export default {

  components: {
    VInput,
    VLabel,
  },

  setup () {
    return { v$: useVuelidate() }
  },

  props: {
    validation: {required: true},
    value: {required: true},
  },

  created() {
    if('instance_iban' in this.value){
      this.bank = {
        iban: this.value.instance_iban,
        bic: this.value.instance_bic,
        bankOwner: this.value.instance_bank_owner,
        bankname: this.value.instance_bank_name,
      }
    }else{
      this.bank = {
        iban: this.value.invoice_iban,
        bic: this.value.invoice_bic,
        bankOwner: this.value.invoice_bank_owner,
        bankname: this.value.invoice_bank_name,
      }
    }
  },

  data() {
    return {
      bank: {
        iban: "",
        bankOwner: "",
        bic: "",
        bankname: "",
      },
      ibanErrorMessage: null,
    }
  },

  computed: {
    computedIbanErrorMessage(){
      if(this.ibanErrorMessage){
        return this.ibanErrorMessage
      }else if(this.validation.invoice_iban){
        return this.validation.invoice_iban.$errors[0]
      }else{
        return null
      }
    }
  },

  watch: {
    bank: {
      deep: true,
      handler(){
        console.log("test", this.ibanErrorMessage ? null : this.bank.iban);
        this.$emit('changed', { 
            iban: this.ibanErrorMessage ? null : this.bank.iban,
            bankOwner: this.bank.bankOwner,
            bic: this.bank.bic,
            bankname: this.bank.bankname,
        })
      },
    }
  },

  methods: {
    validateIban(){
      fetch(`https://admin.jonku.net/api/ibanInformation`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
          },
          body: JSON.stringify({iban: this.bank.iban})
      })
      .then((response) => {
          return response.json();
      })
      .then((json) => {
        if(json.status === "error"){
          this.bank.bic = null
          this.bank.bankname = null
          if(json.message == "IBAN too short"){
            this.ibanErrorMessage = "IBAN ist zu kurz"
          }else{
            this.ibanErrorMessage = json.message
          }
        }else{
          this.ibanErrorMessage = null
          this.bank.bic = json.bankData.bic
          this.bank.bankname = json.bankData.name
        }
      })
      .catch((error) => {
          console.log(error);
      });
    }
  },
}
</script>
<style lang="">
  
</style>