<template>
  <div>
    <div 
      class="relative text-primary bg-white border border-gray-300 rounded-md text-sm sm:text-sm p-px"
      :class="{ 
        'border-red-500 focus:ring-red-500 focus:border-red-500' : error,
        'focus:ring-primary focus:border-primary' : !error,
      }"
    >
      <div v-if="icon" class="absolute flex items-center h-full left-2 pointer-events-none">
        <font-awesome-icon
          :icon="icon"
          class="w-12 text-gray-500"
        />
      </div>

      <div class="flex" :class="{ 'pl-6' : !!icon }">
        <div class="flex-grow flex items-center">
          <input
            @input="$emit('update:modelValue', $event.target.value)"
            @change="$emit('update:modelValue', $event.target.value)"
            :id="name"
            :name="name"
            :type="type"
            :value="modelValue"
            class="w-full p-2 rounded-md placeholder-gray-400 border-none focus:outline-none focus:placeholder-gray-400 focus:ring-0"
            v-bind="$attrs"
          />
        
          <font-awesome-icon v-if="error" icon="exclamation-triangle" class="w-5 h-5 mx-1 text-red-500" />
        </div>
        
        <div v-if="text" class="flex items-center px-2 py-1 bg-primary rounded-r">
          <span class="text-white">{{ text }}</span>
        </div>
      </div>
    </div>
    <p v-if="error" class="mt-1 bg-white text-sm text-red-500">
      <span v-if="isString(error)">{{ error }}</span>
      <span v-if="error.$validator == 'required'">Dies ist ein Pflichtfeld!</span>
      <span v-if="error.$validator == 'email'">Keine gültige E-Mail Adresse!</span>
    </p>
</div>
</template>

<script>
export default {
  name:"VInput",

  inheritAttrs: false,
  
  emits: ['update:modelValue'],
  
  props: {
    modelValue: String,
    icon: { type: String, required: false, default: null },
    error: { default: null },
    text: { type: String, default: null },
    name: { type: String, default: null },
    type: { type: String, default: null },
  },

  methods: {
    isString(value){
      return typeof value === 'string' || value instanceof String
    }
  },
}
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>