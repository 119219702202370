<template lang="">
  <button 
    v-bind="$attrs"
    type="button"
    class="inline-flex items-center px-3 py-2 border-none font-medium rounded-md shadow-sm text-accent text-gray-50 bg-primary hover:bg-accent hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-accent">
    <slot />
  </button>
</template>
<script>
export default {
  name:"VButton",
}
</script>