<template lang="">
  <div class="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 md:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
    <div v-for="price in prices" :key="price" class="border border-primary border-opacity-25 rounded-lg shadow divide-y divide-gray-200 bg-white">
      <div class="flex flex-col h-full p-6">
        <h2 class="mb-0 text-primary">{{ price.title }}</h2>
        <p class="text-xs text-gray-500 italic">
          {{ price.subtitle }}
        </p>

        <p class="flex-grow mt-4 text-sm text-gray-500" v-html="price.text" />
        
        <p class="mt-8">
          <span class="text-xl md:text-3xl font-extrabold text-primary">{{ price.price }}</span>
          <span class="text-base font-medium text-gray-500">{{ price.periode }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      prices: [
        {
          title: "Website",
          text: "Wenn Sie eine einfache Website benötigen, bei der Sie selbst die Inhalte pflegen, können wir Ihnen diese gerne gegen einen geringen Aufpreis mit anbieten. Größere Websites und Hilfe bei der Einrichtung auf Anfrage.",
          price: "ab 5,00 €",
          periode: "/Monat",
          subtitle: "",
        },
        {
          title: "Kundenimport",
          text: "Sie haben bereits eine Software zur Kundenverwaltung genutzt und wollen jetzt beim Umstieg die Daten daraus übernehmen? Gerne helfen wir Ihnen auf Anfrage dabei.",
          price: "auf Anfrage",
          periode: "",
          subtitle: "",
        },
        {
          title: "Weitere Services",
          text: "Sie benötigen Hilfe bei der Einrichtung oder hätten gerne eine Schulung für Ihre Mitarbeitende?<br>Sprechen Sie uns an.",
          price: "auf Anfrage",
          periode: "",
          subtitle: "",
        },
      ]
    }
  },
}
</script>
<style lang="">
  
</style>