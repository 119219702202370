<template lang="">
  <div>
    <div v-if="!reigstrationSuccessfull" class="grid grid-flow-row md:grid-flow-col gap-4 md:gap-10 md:grid-cols-8 px-3 my-10">
      <div class="md:col-start-1 md:col-end-3">
        <nav aria-label="Progress">
          <div class="border border-gray-200 rounded">
            <div v-for="(step, index) in steps" :key="step.name">
              <template v-if="step.completed">
                <div class="relative">
                  <div v-if="step == activeStep" class="absolute w-2 h-full left-0 top-0 bg-primary"></div>
                  <div 
                    @click="setStep(step)"
                    class="flex items-center px-4 py-2 cursor-pointer"
                  >
                    <span style="min-width: 40px; min-height:40px;" class="flex justify-center items-center rounded-full bg-primary">
                      <font-awesome-icon icon="check" class=" text-white text-lg" />
                    </span>
                    
                    <div class="ml-5">
                      <p class="text-primary font-bold uppercase">{{ step.name }}</p>
                      <p class="text-gray-500 text-sm">{{ step.description }}</p>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else-if="step === activeStep || (steps[index-1] == activeStep && activeStep.completed)">
                <div class="relative">
                  <div v-if="step === activeStep" class="absolute w-2 h-full left-0 top-0 bg-primary"></div>
                  <div 
                    @click="setStep(step)"
                    class="flex items-center px-4 py-2 cursor-pointer"
                  >
                    <span style="min-width: 40px; min-height:40px;" class="flex justify-center items-center rounded-full border-2 font-bold border-primary">
                      {{ index + 1 }}
                    </span>
                    
                    <div class="ml-5">
                      <p class="text-primary font-bold uppercase">{{ step.name }}</p>
                      <p class="text-gray-500 text-sm">{{ step.description }}</p>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="flex items-center px-4 py-2">
                  <span style="min-width: 40px; min-height:40px;" class="flex justify-center items-center w-10 h-10 rounded-full border text-gray-500 border-gray-500">
                    {{ index + 1 }}
                  </span>
                  
                  <div class="ml-5">
                    <p class="text-gray-500 font-bold uppercase">{{ step.name }}</p>
                    <p class="text-gray-500 text-sm">{{ step.description }}</p>
                  </div>
                </div>
              </template>

              <template v-if="index < steps.length-1">
                <div class="h-px bg-gray-200"/>
              </template>
            </div>
          </div>
        </nav>
      </div>
      <div class="mt-6 md:mt-0 md:col-start-3 md:col-end-9 md:px-8">
        <h2 class="text-xl font-bold text-primary mb-4">{{ activeStep.name }}</h2>

        <div v-if="activeStep === steps[0]">
          <form class="grid grid-cols-6 gap-y-6 gap-x-4">
            
            <div class="col-span-6">
              <p class="text-sm text-primary">Diese Daten werden benutzt um einen Ansprechpartner zu haben und um den ersten Mitarbeiter anzulegen.</p>
            </div>

            <div class="col-start-1 col-span-3">
              <select v-model="form.contact.type" class="border border-gray-300 p-2 text-sm rounded-md outline-none">
                <option value="" disabled selected>-- Option auswählen --</option>
                <option value="MALE">Herr</option>
                <option value="FEMALE">Frau</option>
                <option value="COMPANY">Firma</option>
              </select>
              <p v-if="v$.form.contact.type.$errors[0]" class="w-full mt-1 bg-white text-sm text-red-500">
                Dies ist ein Pflichtfeld!
              </p>
            </div>
          
            <v-label class="col-start-1 col-span-3" title="Vorname">
              <v-input v-model="form.contact.first_name" :error="v$.form.contact.first_name.$errors[0]" name="given-name" type="text" />
            </v-label>
            
            <v-label class="col-span-3" title="Nachname">
              <v-input v-model="form.contact.last_name" :error="v$.form.contact.last_name.$errors[0]" name="family-name" type="text" />
            </v-label>

            <v-label class="col-start-1 col-span-3" title="E-Mail">
              <v-input v-model="form.contact.mail" :error="v$.form.contact.mail.$errors[0]" icon="envelope" name="email" type="email" />
            </v-label>
            
            <v-label class="col-span-3" title="Telefonnummer">
              <v-input v-model="form.contact.phone" :error="v$.form.contact.phone.$errors[0]" icon="phone" name="tel" type="tel" />
            </v-label>

            <h4 class="col-start-1 col-span-6">Adressdaten</h4>

            <v-label class="col-span-5" title="Straße">
              <v-input v-model="form.contact.street" :error="v$.form.contact.street.$errors[0]" name="address-line1" type="text" />
            </v-label>
            
            <v-label class="col-span-1" title="Hausnummer">
              <v-input v-model="form.contact.number" :error="v$.form.contact.number.$errors[0]" name="address-line2" type="text" />
            </v-label>
            
            <v-label class="col-start-1 col-span-1" title="Postleitzahl">
              <v-input v-model="form.contact.zip" :error="v$.form.contact.zip.$errors[0]" name="postal-code" type="text" />
            </v-label>
            
            <v-label class="col-span-5" title="Stadt">
              <v-input v-model="form.contact.city" :error="v$.form.contact.city.$errors[0]" name="address-level2" type="text" />
            </v-label>        
          </form>

          <v-button class="mt-6" @click="nextStep(steps[1])">Nächster Schritt</v-button>

        </div>
        <div v-if="activeStep === steps[1]" class="mt-4">
          <form class="grid grid-cols-6 gap-y-6 gap-x-4">

            <div class="col-span-3 md:col-span-6">
              <p class="text-sm text-primary">Diese Daten werden benutzt für die Rechnungsgenerierung und zur Erstellung Ihrer JONKU Anwendung.</p>
            </div>

            <v-label class="col-start-1 col-end-4" title="Firmenname">
              <v-input v-model="form.company.company_name" :error="v$.form.company.company_name.$errors[0]" />
            </v-label>
            
            <v-label class="col-start-1 col-end-4" title="Website">
              <v-input v-model="form.company.homepage" :error="v$.form.company.homepage.$errors[0]" />
            </v-label>

            <h4 class="col-start-1 col-span-6">Bankdaten</h4>
            <p class="col-start-1 col-span-6 text-sm text-gray-500">Diese Bankverbindung wird dem Kunden auf der Rechnung angezeigt.</p>
            <iban-form :value="form.company" @changed="invoiceBankChanged" class="col-start-1 col-span-6" :validation="v$.form.company" />
            
            <h4 class="col-start-1 col-span-6">Steuerdaten</h4>
            <p class="col-start-1 col-span-6 text-sm text-gray-500">Die Steuerdaten sind optional und können später im System nachgepflegt werden.</p>
            
            <v-label class="col-start-1 col-span-3" title="Steueridentifikationsnummer">
              <v-input v-model="form.company.tax" :error="v$.form.company.tax.$errors[0]" />
            </v-label>
            
            <v-label class="col-span-3" title="Umsatzsteuernummer">
              <v-input v-model="form.company.salestax" :error="v$.form.company.salestax.$errors[0]" />
            </v-label>
            
            <v-label class="col-start-1 col-span-3" title="Handelsregisternummer">
              <v-input v-model="form.company.commercial_register_number" :error="v$.form.company.commercial_register_number.$errors[0]" />
            </v-label>
            
            <v-label class="col-span-3" title="Registergericht">
              <v-input v-model="form.company.registration_court" :error="v$.form.company.registration_court.$errors[0]" />
            </v-label>
          </form>

          <v-button class="mt-6" @click="nextStep(steps[2])">Nächster Schritt</v-button>
        </div>
        <div v-else-if="activeStep === steps[2]">
          <div class="grid grid-cols-6 gap-y-6 gap-x-4">
            
            <v-label class="col-start-1 col-end-6 md:col-end-3" title="Name und Url der Anwendung">
              <v-input v-model="form.instance.name" text=".jonku.net" :error="instanceNameError ? instanceNameError : v$.form.instance.name.$errors[0]" maxlength="25" />
            </v-label>
            
            <!--<v-label class="col-start-1 col-end-6 md:col-end-4" title="Logo">
              <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <div class="relative cursor-pointer bg-white rounded-md font-medium text-primary hover:text-blue-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <span>Wählen Sie eine Datei aus</span>
                      <input type="file" class="sr-only">
                    </div>
                    <p class="pl-1">oder ziehen Sie sie hier rein</p>
                  </div>
                  <p class="text-xs text-gray-500">
                    PNG oder JPG bis zu 10MB
                  </p>
                </div>
              </div>
            </v-label>-->
            <h4 class="col-start-1 col-span-6">Bankdaten</h4>
            <p class="col-start-1 col-span-6 text-sm text-gray-500">Diese Bankverbindung wird zur Abrechnung von JONKU benötigt.</p>
            <iban-form :value="form.instance" @changed="instanceBankChanged" class="col-start-1 col-span-6" :validation="v$.form.instance" />
          </div>

          <p v-if="registrationError" class="mt-6 text-sm text-red-500">{{registrationError}}</p>

          <v-button class="mt-6" @click="nextStep(steps[3])">Registrieren</v-button>
        </div>
      </div>
    </div>
    <div v-if="reigstrationSuccessfull" class="flex justify-center mt-12">
      <div class="w-1/2">

        <h1 class="mt-12 pb-10 text-center transform text-green-600 -rotate-3">WOHOO ES IST VOLLBRACHT!</h1>

        <h3 class="text-primary">Danke für Ihre Registrierung!</h3>
        <p>Wir sind gerade dabei Ihre Installation von JONKU zu bearbeiten, dies kann wenige Minuten dauern.</p>
        <p>Sobald wir damit fertig sind erhalten Sie eine E-Mail mit allen benötigten Informationen.</p>
        
        <div class="mt-6">
          <p>In dieser Zeit können Sie sich gerne einen Kaffee holen oder Sie beschäftigen sich schonmal mit <a href="#" class="text-blue-900 underline">den ersten Schritten</a>.</p>
        </div>

        <p class="mt-6">Wir bedanken uns für Ihr Vertrauen und wir hoffen dass Sie begeistert sein werden!</p>
      </div>
    </div>
  </div>
</template>
<script>
import VInput from '../components/v-input.vue';
import VLabel from '../components/v-label.vue';
import VButton from '../components/v-button.vue';
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import IbanForm from '../components/iban-form.vue'

export default {
  name: "Registration",

  components: {
    VInput,
    VButton,
    VLabel,
    IbanForm,
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      steps: [
        {
          key: "contact",
          name: "Kontaktdaten",
          description: "Informationen für Kontaktmöglichkeiten",
          completed: false,
        },
        {
          key: "company",
          name: "Firmendaten",
          description: "Benötigte Daten der Firma zur Einrichtung von JONKU",
          completed: false,
        },
        {
          key: "instance",
          name: "JONKU konfigurieren",
          description: "Informationen um Ihre Anwendung zu personalisieren",
          completed: false,
        },
      ],
      activeStep: null,
      form: {
        contact: {
          //step 1
          type: '',
          first_name: '',
          last_name: '',
          mail: '',
          phone: '',
          street: '',
          number: '',
          city: '',
          zip: '',
        },

        company: {
          //step 2
          company_name: '',
          homepage: '',
          invoice_iban: '',
          invoice_bic: '',
          invoice_bank_owner: '',
          invoice_bank_name: '',
          tax: '',
          salestax: '',
          commercial_register_number: '',
          registration_court: '',
        },

        instance: {
          //step 3
          logo: null,
          name: '',
          instance_iban: '',
          instance_bic: '',
          instance_bank_owner: '',
          instance_bank_name: '',
        }
      },

      instanceNameError : null,
      registrationError : null,
      reigstrationSuccessfull: false,
    }
  },

  validations () {
    return {
      form: {
        contact: {
          type: { required },
          first_name: { required },
          last_name: { required },
          mail: { required, email },
          phone: { required },
          street: { required },
          number: { required },
          city: { required },
          zip: { required },
        },

        company: {
          //step 2
          company_name: { required },
          homepage: {},
          invoice_iban: { required },
          invoice_bic: { required },
          invoice_bank_owner: { required },
          invoice_bank_name: { required },
          tax: {},
          salestax: {},
          commercial_register_number: {},
          registration_court: {},
        },

        instance: {
          name: { required },
        },
      }
    }
  },

  created(){
    this.activeStep = this.steps[0]
  },

  computed: {
    urlAnchor(){
      return window.location.hash.substr(1);
    },
  },

  methods: {
    setLocationHash(hash){
      window.location.hash = hash
    },

    nextStep(step){

      this.v$.$reset()

      switch(this.activeStep.key){
        case "contact":
          this.v$.form.contact.$touch()
          this.activateNextStep(step)
          break;
        case "company":
          this.v$.form.company.$touch()
          this.activateNextStep(step)
          break;
        case "instance":
          this.v$.form.instance.$touch()

          if (this.v$.$errors.length > 0) return
          this.apiRegistration()
          break;
      }
    },

    activateNextStep(step){
      if (this.v$.$errors.length > 0) return

      //set old step completed
      this.activeStep.completed = true
      this.setStep(step)
    },

    instanceBankChanged(data){
      this.form.instance.instance_iban = data.iban
      this.form.instance.instance_bic = data.bic
      this.form.instance.instance_bank_owner = data.bankOwner
      this.form.instance.instance_bank_name = data.bankname
    },

    invoiceBankChanged(data){
      this.form.company.invoice_iban = data.iban
      this.form.company.invoice_bic = data.bic
      this.form.company.invoice_bank_owner = data.bankOwner
      this.form.company.invoice_bank_name = data.bankname
    },

    setStep(step){
      //set new stop
      this.setLocationHash(step.name)
      this.activeStep = step
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    apiRegistration(){
      fetch("https://admin.jonku.net/api/instances/register", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
          },
          body: JSON.stringify({
            "instance_type_id": 13,
            "type": "COMPANY",
            ...this.form.contact,
            ...this.form.company,
            ...this.form.instance,
          }),
      })
      .then((response) => {
          return response.json();
      })
      .then((json) => {
          if(json.id){
            this.reigstrationSuccessfull = true
          }else if(json.errors && json.errors.name){
            this.instanceNameError = "Der Name ist leider schon vergeben!"
          }else{
            this.registrationError = "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut oder nehmen mit uns Kontakt auf."
          }
      })
      .catch((error) => {
          console.log(error);
      });
    }
  },
}
</script>
<style lang="">
  
</style>