<template lang="">
  <div class="w-full md:w-1/2 checklist flex flex-col items-center">
    <div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props:{
    title: String,
  } 
}
</script>
<style>
  .checklist .checklist-item{
    @apply mt-4; 
  }

  .checklist .checklist-item:first-child{
    @apply mt-0; 
  }
</style>