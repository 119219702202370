<template lang="">
  <div class="checklist-item flex">  
    <div style="min-width:40px; height:40px;" class="inline-flex justify-center items-center rounded-full p-2 bg-primary">
      <font-awesome-icon icon="check" class="text-green-500" />
    </div>
    
    <div class="inline-block break-words ml-4 text-left">
      <h3 class="mb-0 text-white">{{ title }}</h3>
      <p>{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    title: { type: String, default: null },
    text: String,
  } 
}
</script>
<style lang="">
  
</style>