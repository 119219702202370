<template>
  <div class="home">
    <div class="bg-gray-100">
      <main>
        <div class="relative bg-primary">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
          <div class="mx-auto sm:px-6 lg:px-8">
            <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div class="absolute inset-0">
                <img class="h-full w-full object-cover" src="/image/laptop-593673.jpg" alt="People working on laptops">
                <div class="absolute inset-0 bg-gradient-to-r from-primary to-blue-800 mix-blend-multiply  "></div>
              </div>
              <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span class="block text-white">Übernimm die Kontrolle</span>
                  <span class="block text-blue-200">& erreiche bessere Strukturen</span>
                </h1>
                <p class="mt-6 max-w-lg mx-auto text-center text-xl text-blue-200 sm:max-w-3xl">
                  Online Kundenverwaltung, Rechnungswesen, Zeiterfassung und vieles mehr<br>
                  Alles in einem Programm
                </p>

                <div class="flex items-center justify-center mt-10">
                  <a href="/registrieren" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-primary bg-white hover:bg-indigo-50 sm:px-8">
                      Loslegen
                    </a>
                </div>

                <!--<div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a href="/registrieren" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-primary bg-white hover:bg-indigo-50 sm:px-8">
                      Loslegen
                    </a>
                    <a href="#" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                      Live Demo
                    </a>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>

        <Banner title="Das bieten wir" id="features">
          <Features>
            <FeaturesItem title="Rechnungs- & Mahnwesen" text="Schreiben Sie Angebote, erstellen Sie leicht eine Rechnung daraus und bei Bedarf mit einem Klick eine Mahnung." icon="file-invoice-dollar" />
            <FeaturesItem title="Verträge" text="Legen Sie Vertragsvorlagen an um schnell Verträge für Neukunden zu erstellen." icon="file-contract" />
            <FeaturesItem title="Zeiterfassung" text="Erfassen Sie Ihre Zeiten und stellen Sie diese direkt in Rechnung." icon="clock" />
            <FeaturesItem title="Aufgaben" text="Organisieren Sie Ihr Team und Ihre Arbeitsabläufe." icon="calendar-alt" />
          </Features>
        </Banner>

        <Banner title="Das macht uns aus" primary id="about">
          <Checklist>
            <ChecklistItem title="Intuitiv" text="Durch direkte Zusammenarbeit mit Energie- und Steuerberatern wurde eine leicht verständliche Oberfläche geschaffen." />
            <ChecklistItem title="Flexibel" text="Aktivieren Sie nur die Funktionen, die Sie auch brauchen und konfigieren Sie Tabellen so wie es für Ihren Arbeitsalltag am Effektivsten ist." />
            <ChecklistItem title="Umfangreich" text="Nutzen Sie gerne für alles verschiedene Programme? Wir auch nicht, deswegen haben wir JONKU entwickelt." />
            <ChecklistItem title="Geräteunabhängig" text="Ob Windows, Mac oder Tablet, immer und überall die volle Kontrolle." />
            <ChecklistItem title="Zukunftsorientiert" text="Ständige Weiterentwicklung und neue Funktionen, aber Sie entscheiden, was Sie brauchen." />
          </checklist>
        </Banner>

        <Banner title="Wer wir sind" id="company">
          <p>
            Wir sind ein junges IT Kleinunternehmen, spezialisiert auf die Erstellung von Websites und Apps. Unsere Kunden sind in der Regel Klein- und Mittelständische Unternehmen aus allen Bereichen. Oft wurden wir gefragt, ob wir gute und günstige Software kennen zum Verwalten von Kunden, Rechnung und Aufgaben. Klar gibt es viele gute Programme auf dem Markt, aber sie alle können entweder nicht alles was man braucht oder sind zu kompliziert zu bedienen. Da wir selbst auch nicht für alles ein eigenes Programm kaufen oder abonnieren wollten, haben wir uns dazu entschlossen, zusammen mit unseren langjähirgen Kunden, etwas eigenes zu entwickeln.<br />
            So haben wir nun eine Online Kundenverwaltung entwickelt, mit der man nicht nur Kundendaten verwalten kann, sondern auch Angebote, Rechnungen, Mahnungen, Verträge, Aufgaben und Arbeitszeiten. Das ist jedoch nur der Anfang, wir werden noch mehr Module einbauen, die man jederzeit bei Bedarf aktivieren kann, z.B. für Buchhaltung, Projeke, Dateispeicherung, Exportmöglichkeiten für Steuerberater und vieles mehr.
          </p>
        </Banner>

        <collage
          title="Für alles nur 5 Minuten"
          text="Klicken Sie neue Rechnungen, Verträge u.ä. schnell und unkompliziert zusammen.
          Hinterlegen Sie Vorlagen für Angebote, Rechnungen, Verträge und Zahlungsziele.
          Legen Sie Produkte an und geben Sie Standartwerte für alles an."
          quote="Zusammenklicken. Prüfen. Buchen."
          image="/image/beispiel_rechnung.png"
          image-alt="Beispielbild der Rechnungserstellung"
          class="pt-16 pb-16"
        />

        <collage
          title="Den Fokus aufs Wesentliche"
          text="Eine schöne Oberfläche, die einen nicht mit unzähligen Button erschlägt.
          Aktivieren Sie nur die Funktionalitäten, die Sie auch brauchen.
          Sie arbeiten z.B. mit Lastschrift, dann aktivieren Sie es mit einem Klick.
          Wenn Sie Lastschrift in Zukunft doch nicht mehr anbieten wollen, können Sie es jederzeit wieder deaktivieren und alle zugehörigen Button verschwinden."
          quote="Aktivieren und loslegen."
          image="/image/beispiel_module.png"
          image-alt="Beispielbild der Modulverwaltung"
          image-left
          class="pt-16 pb-16"
        />

        <collage
          title="So wie Sie es brauchen"
          text="Sie wollen meistens nur bestimmte Aufgaben sehen, dann stellen Sie dafür einfach einen Standardfilter ein.
          Sie wollen meistens Ihre Kunden alphabetisch sortiert haben, dann stellen Sie dafür einfach eine Standardsortierung ein.
          Sie brauchen nur bestimmte Informationen in Ihren Tabellen, dann stellen Sie dafür einfach Standardfelder ein.
          Stellen Sie ganz einfach jede Tabelle so ein, wie Sie am Besten damit arbeiten können und wenn Ihnen Felder fehlen sollten wie z.B. Interessen Ihrer Kunden, dann fügen Sie die einfach hinzu."
          quote="Einmal einstellen, immer Zeit sparen."
          image="/image/beispiel_tabellen.png"
          image-alt="Beispielbild der Tabelleneinstellung"
          class="pt-16 pb-16"
        />

        <Banner class="mt-16" title="Preisübersicht" text="In jedem Paket sind 1 GB kostenloser Datenspeicher enthalten, jedes weitere angefangene GB kostet 0,70 € netto / Monat." primary id="preise">
          <Prices></Prices>

          <Extras class="mt-5"></Extras>
        </Banner>

        <!-- CTA Section -->
        <div class="bg-white">
          <div class="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span class="block">Sie sind interessiert ?</span>
              <span class="block bg-gradient-to-r from-primary to-blue-800 bg-clip-text text-transparent">Dann starten Sie jetzt mit der Testphase!</span>
            </h2>
            <a href="/registrieren" class="flex items-center justify-center w-full lg:w-48 px-4 py-3 mt-4 lg:mt-0 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-primary to-blue-800 hover:from-blue-800 hover:to-primary">
              Loslegen
            </a>
          </div>
        </div>
      </main>

      <footer class="bg-gray-50" aria-labelledby="footerHeading">
        <h2 id="footerHeading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
          <div class="xl:grid xl:grid-cols-1 xl:gap-8">
            <div class="grid grid-cols-3 gap-8 xl:col-span-2">
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Support
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <a href="tel:004964412492610" class="text-base text-gray-500 hover:text-gray-900">
                        Telefonisch
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@jonku.net" class="text-base text-gray-500 hover:text-gray-900">
                        E-Mail
                      </a>
                    </li>
                    <li>
                      <a href="https://get.anydesk.com/rQmrH9v2/AnyDesk_JONKU.exe" class="text-base text-gray-500 hover:text-gray-900">
                        Fernwartung AnyDesk
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Kontakt
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <a href="tel:004964412492610" class="text-base text-gray-500 hover:text-gray-900">
                        0441 249 261 0
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@jonku.net" class="text-base text-gray-500 hover:text-gray-900">
                        info@jonku.net
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Bürozeiten
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <p class="text-base text-gray-500">
                        Montag bis Freitag
                      </p>
                    </li>
                    <li>
                      <p class="text-base text-gray-500">
                        9 - 13 Uhr
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-6">
            <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; JONKU
            </p>
            <div class="md:order-2">
              <a href="impressum" class="text-base text-gray-500 hover:text-gray-900">
                Impressum
              </a>
              <a href="datenschutz" class="text-base text-gray-500 hover:text-gray-900 mx-6">
                Datenschutz
              </a>
              <a href="agb" class="text-base text-gray-500 hover:text-gray-900">
                AGB
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Collage from '../components/collage.vue';
import Banner from '../components/banner.vue';
import Features from '../components/features.vue';
import FeaturesItem from '../components/features-item.vue';
import Checklist from '../components/checklist.vue';
import ChecklistItem from '../components/checklist-item.vue';
import Prices from '../views/Prices.vue';
import Extras from '../views/Extras.vue';

export default {
  name: 'Home',

  components: {
    Collage,
    Banner,
    Features,
    FeaturesItem,
    Checklist,
    ChecklistItem,
    Prices,
    Extras,
  }
}
</script>
