<template lang="">
  <div>
    <div>
      <span class="flex items-center justify-center h-12 w-12 rounded-md bg-primary">
        <!-- Heroicon name: outline/chat-alt -->
        <font-awesome-icon :icon="icon" class="text-xl text-white" />
      </span>
    </div>
    <div class="mt-6">
      <h3 class="text-lg font-medium text-black">{{ title }}</h3>
      <p class="mt-2 text-base">
        {{ text }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "BannerItem",

  props: {
    title: String,
    text: String,
    icon: String,
  }
}
</script>
<style lang="">
  
</style>