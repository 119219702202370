<template lang="">
  <div class="overflow-hidden">
      <div class="lg:mx-auto lg:px-8 lg:grid lg:grid-cols-8 lg:grid-flow-col-dense lg:gap-24">
        <div 
          class="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0"
          :class="{ 'col-start-5 col-end-9 2xl:col-end-8' : imageLeft, 'col-start-1 col-end-5 2xl:col-start-2' : !imageLeft }"
        >
          <div>
            <div>
              <h2 class="text-3xl font-extrabold tracking-tight text-primary">
                {{ title }}
              </h2>
              <p class="mt-4 text-lg text-gray-500 whitespace-pre-line">
                {{ text }}
              </p>
            </div>
          </div>
          <div v-if="quote" class="mt-8 border-t border-gray-200 pt-6">
            <blockquote>
              <div>
                <p class="text-base text-gray-500">
                  &ldquo;{{ quote }}&rdquo;
                </p>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0" :class="{ 'col-start-1 col-end-5 mr-8 2xl:col-start-2' : imageLeft, 'col-start-5 col-end-9 ml-8 2xl:col-end-8' : !imageLeft }">
          <div class="pl-4 sm:pl-6 lg:px-0 lg:m-0 lg:h-full">
            <img class="h-auto w-full xl:max-w-2xl rounded-xl shadow-xl ring-1 ring-black ring-opacity-5" :src="image" :alt="imageAlt" @click="overlay = !overlay" />
          </div>
        </div>
      </div>
      <div v-if="overlay" class="flex justify-center items-center fixed w-full h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-70" @click="overlay = !overlay">
        <div class="relative max-w-7xl m-4">
          <div style="min-width:40px; height:40px;" class="absolute -top-8 -right-2 inline-flex justify-center items-center rounded-full p-2 bg-black">
            <font-awesome-icon icon="times" class="text-white" />
          </div>
          <img class="rounded-xl w-full shadow-xl ring-1 ring-black ring-opacity-5" :src="image" :alt="imageAlt" />
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "Collage",

  props: {
    title: String,
    text: String,
    quote: { type: String, default: null},
    image: String,
    imageAlt: { type: String, default: ""},
    imageLeft: Boolean,
  },

  data() {
    return {
      overlay: false,
    }
  },
}
</script>
<style lang="">
  
</style>