<template>
  <div class="home">
    <div class="bg-gray-100">
      <main class="w-2/3 mx-auto py-10">
        <div class="frame-inner">
          <h1>Impressum</h1>

          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>Joachim Rothkegel<br />
          JONKU<br />
          JOTEK Online Kundenverwaltung<br />
          Am Elisabethstein 8<br />
          26215 Wiefelstede</p>

          <h2>Kontakt</h2>
          <p>Telefon: 0441 249 261 0.<br />
          Telefax: -<br />
          E-Mail: info@jonku.net</p>

          <h2>Umsatzsteuer-ID</h2>
          <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE294256667</p>

          <h2>Redaktionell verantwortlich</h2>
          <p>Joachim Rothkegel<br />
          Am Elisabethstein 8<br />
          26215 Heidkamp - Wiefelstede</p>

          <h2>EU-Streitschlichtung</h2>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

          <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
      </main>

      <footer class="bg-gray-50" aria-labelledby="footerHeading">
        <h2 id="footerHeading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
          <div class="xl:grid xl:grid-cols-1 xl:gap-8">
            <div class="grid grid-cols-3 gap-8 xl:col-span-2">
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Support
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                        Dokumentation
                      </a>
                    </li>

                    <li>
                      <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                        Hilfe
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Kontakt
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <a href="tel:00494412492610" class="text-base text-gray-500 hover:text-gray-900">
                        0441 249 261 0
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@jonku.net" class="text-base text-gray-500 hover:text-gray-900">
                        info@jonku.net
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="mt-12 md:mt-0">
                  <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Bürozeiten
                  </h3>
                  <ul class="mt-4 space-y-4">
                    <li>
                      <p class="text-base text-gray-500">
                        Montag bis Freitag
                      </p>
                    </li>
                    <li>
                      <p class="text-base text-gray-500">
                        9 - 13 Uhr
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-6">
            <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; JONKU
            </p>
            <div class="md:order-2">
              <a href="impressum" class="text-base text-gray-500 hover:text-gray-900">
                Impressum
              </a>
              <a href="datenschutz" class="text-base text-gray-500 hover:text-gray-900 mx-6">
                Datenschutz
              </a>
              <a href="agb" class="text-base text-gray-500 hover:text-gray-900">
                AGB
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Imprint',

  components: {

  }
}
</script>