<template lang="">
  <div>
    <div v-if="primary" class="bg-gradient-to-r from-primary to-blue-800">
      <div class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 class="text-3xl font-extrabold text-white tracking-tight">
          {{ title }}
        </h2>
        <p class="mt-4 max-w-3xl text-lg text-blue-200">
          {{ text }}
        </p>

        <div class="mt-6 text-blue-200">
          <slot />
        </div>

      </div>
    </div>

    <div v-if="!primary" class="w-full text-primary">
      <div class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 v-if="title" class="text-3xl font-extrabold tracking-tight">
          {{ title }}
        </h2>
        <p class="my-4 max-w-3xl text-lg text-grey-500">
          {{ text }}
        </p>

        <div class="mt-6 text-grey-500">
          <slot />
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: "Banner",

  props: {
    primary: Boolean,
    title: String,
    text: String,
  }
}
</script>
<style lang="">

</style>