import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Registration from '../views/Registration.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/registrieren',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Imprint
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Privacy
  },
  {
    path: '/agb',
    name: 'AGB',
    component: Terms
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
