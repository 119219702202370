<template lang="">
  <div class="max-w-4xl mx-auto px-4 py-6 sm:px-6 sm:pt-6 sm:pb-24 lg:max-w-7xl lg:pt-6 lg:px-8">
    <div class="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "Banner",

  props: {
    title: String,
    text: String,
  }
}
</script>
<style lang="">

</style>