<template lang="">
  <div class="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 md:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
    <div v-for="price in prices" :key="price" class="border border-primary border-opacity-25 rounded-lg shadow divide-y divide-gray-200 bg-white">
      <div class="flex flex-col h-full p-6">
        <!-- Titel / Untertitel -->
        <h2 class="mb-0 text-primary">{{ price.title }}</h2>

        <p class="text-xs text-gray-500 italic">
          {{ price.condition }}
        </p>

        <!-- Beschreibung -->
        <p class="flex-grow mt-4 text-sm text-gray-500">{{ price.text }}</p>

        <!-- Leistungen -->
        <!--div class="mt-4">
          <div v-for="service in price.services" :key="service" class="text-left text-sm text-gray-500">
            <p>- <span class="mr-1 font-bold text-gray-700">{{ service.title }}</span></p>
          </div>
        </div-->

        <!-- Preis -->
        <p class="mt-8">
          <span class="text-xl md:text-3xl font-extrabold text-primary">{{ price.price }} €</span>
          <span class="text-base font-medium text-gray-500">{{ price.periode }}</span>
        </p>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      prices: [
        {
          title: "Solo",
          text: "Wir unterstützen die Solo-Selbstständigen und bieten Ihnen mit einem günstigen Paket einen guten Einstieg.",
          price: "10,00",
          periode: " /Monat",
          condition: "für max. 1 Benutzerkonto",
          services: [
            { title: "1 freier Mitarbeiter*in" },
            { title: "1 Stunde Support pro Monat kostenlos" },
          ]
        },
        {
          title: "Team",
          text: "Kleines Team, kleiner Preis. Für nur 10€ mehr, können Sie direkt zwei weitere Mitarbeitende hinzufügen.",
          price: "20,00",
          periode: " /Monat",
          condition: "für 2 - 3 Benutzerkonten",
          services: [
            { title: "3 freie Mitarbeitende" },
            { title: "1 Stunde Support pro Monat kostenlos" },
          ]
        },
        {
          title: "Unternehmen",
          text: "Fügen Sie jederzeit neue Mitarbeitende hinzu.",
          price: "+8,00",
          periode: " / Monat / Benutzerkonto",
          condition: "ab 4 Benutzerkonten",
          services: null
        },
      ]
    }
  },
}
</script>
<style lang="">
  
</style>