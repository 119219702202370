
<template>
  <header>
    <Popover class="relative bg-primary">
      <div class="flex justify-between items-center mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <a href="/">
            <span class="sr-only">Workflow</span>
            <img
              class="h-10 rounded w-auto sm:h-10"
              src="logo.png"
              alt="Jonku"
            >
          </a>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton class="rounded-md p-2 inline-flex items-center justify-center text-white hover:bg-blue-600 hover:bg-opacity-60 focus:outline-none">
            <span class="sr-only">Open menu</span>
            <MenuIcon
              class="h-6 w-6"
              aria-hidden="true"
            />
          </PopoverButton>
        </div>
        <nav
          class="hidden md:flex space-x-10"
        >
          <router-link 
            v-for="item in navigation"
            :key="item.name"
            :to="item.href"
            class="p-1 text-base font-medium text-gray-100 hover:bg-blue-600 hover:bg-opacity-60 rounded"
          >
            {{ item.name }} 
          </router-link>
        </nav>
      </div>

      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-primary divide-y-2 divide-gray-50">
            <div class="pt-5 pb-6 px-5">
              <div class="flex items-center justify-between">
                <div>
                  <img
                    class="h-8 w-auto"
                    src="/logo.png"
                    alt="Jonku"
                  >
                </div>
                <div class="-mr-2">
                  <PopoverButton class="rounded-md p-2 inline-flex items-center justify-center text-gray-100 hover:bg-blue-600 hover:bg-opacity-60 focus:outline-nones">
                    <span class="sr-only">Close menu</span>
                    <XIcon
                      class="h-6 w-6"
                      aria-hidden="true"
                    />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-6">
                <nav class="grid grid-cols-1 gap-7">
                  <PopoverButton
                    v-for="item in navigation"
                    :key="item.name"
                    class="-m-3 p-3 rounded-lg hover:bg-blue-600 hover:bg-opacity-60"
                  >
                    <router-link 
                      :to="item.href"
                      class="flex items-center"
                    >
                      <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-600 to-indigo-600 text-white">
                        <component
                          :is="item.icon"
                          class="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-4 text-base font-medium text-gray-100">
                        {{ item.name }}
                      </div>
                    </router-link>
                  </PopoverButton>
                  
                </nav>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </header>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import {
  MenuIcon,
  XIcon,
  HomeIcon,
  TrendingUpIcon,
} from '@heroicons/vue/outline'

const navigation = [
  {
    name: 'Startseite',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'Loslegen',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '/registrieren',
    icon: TrendingUpIcon,
  },
]

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
  },

  setup() {
    return {
      navigation,
    }
  },
}
</script>


