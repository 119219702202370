<template lang="">
  <label class="font-medium text-gray-700 text-sm">
    {{ title }}
    <slot />
  </label>
</template>
<script>
export default {
  props: {
    title: String,
  }
}
</script>
<style lang="">
  
</style>